import { useState } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useLenis } from "@studio-freight/react-lenis";
import RoutesMap from "../../constants/routes";
import { supportedLanguages } from "../../constants/languages";
import { Button } from "../ui/button/Button";
import NavBar from "../menu/NavBar";
import MobileMenu from "../menu/MobileMenu";
import { ControlsContainer, HeaderContainer, LogoIconComponent } from "./HeaderComponents";

const getNextLanguage = (currLng: string) => {
    const currInd = supportedLanguages.indexOf(currLng) || 0;
    const newInd = currInd + 1;
    return (newInd >= supportedLanguages.length) ? supportedLanguages[0] : supportedLanguages[newInd];
}

const Header = () => {
    const lenisInstance = useLenis();
    const [nextLanguage, setNextLanguage] = useState(getNextLanguage(i18next.language));
    const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
    const handleLanguageChange = () => {
        i18next.changeLanguage(nextLanguage, (err) => {
            if (err) return console.log("something went wrong loading locales", err);
        }).then(() => {
            const newNextLanguage = getNextLanguage(i18next.language);
            setNextLanguage(newNextLanguage);
        });
    };

    const toggleScroll = (isOpen?: boolean) => {
        if (isOpen) {
            lenisInstance?.stop();
        } else {
            lenisInstance?.start();
        }
    };

    const toggleOpenMobileMenu = () => {
        toggleScroll(!openMobileMenu)
        setOpenMobileMenu(!openMobileMenu);
    }

    const closeMobileMenu = () => {
        toggleScroll(false);
        setOpenMobileMenu(false);
    }

    return (
        <HeaderContainer>
            <Link to={RoutesMap.main} onClick={closeMobileMenu}>
                <LogoIconComponent />
            </Link>
            <NavBar />
            <ControlsContainer>
                <Button variant="darkTertiary" size="S" onClick={handleLanguageChange} >
                    {nextLanguage?.toUpperCase()}
                </Button>
                <MobileMenu
                    open={openMobileMenu}
                    toggleOpen={toggleOpenMobileMenu}
                />
            </ControlsContainer>
        </HeaderContainer>
    )
};

export default Header;
