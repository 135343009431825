import styled, { css } from "styled-components";
import { ParagraphHero } from "../../../ui/text/ParagraphComponents";
import OurLifeImage from "../../../../resources/images/our-life.jpg";

export const OurLifeSectionContainer = styled.section`
    padding: 256rem 40rem 128rem;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black };
    
    display: flex;
    flex-direction: row;
    gap: 80rem;

    ${({ theme }) => css`
        @media ${theme.media.large} {
            flex-direction: column;
            gap: 48rem;
        }

        @media ${theme.media.medium} {
            padding: 192px 16px 96px;
            gap: 32px;
        }
    `};
`;

export const Header = styled(ParagraphHero)`
    line-height: 100%;
    font-weight: 700;

    ${({ theme }) => css`
        @media ${theme.media.medium} {
            font-size: 40px;
        }
    `};
`;

export const TextWrapping = styled.br`
    ${({ theme }) => css`
        @media ${theme.media.large} {
            display: none;
        }
    `};
`;

export const ContentContainer = styled.div`
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ theme }) => css`
        @media ${theme.media.large} {
            flex: unset;
            gap: 32rem;
        }
        @media ${theme.media.medium} {
            flex: unset;
            gap: 24px;
        }
    `};
`;

export const DescriptionBlock = styled.div``;

export const OurLifeImageContainer = styled.div`
    flex: 0.5;
    height: 480rem;
    width: 100%;

    background-image: url(${OurLifeImage});
    background-position: center;
    background-size: cover;
    border-radius: 16px;

    ${({ theme }) => css`
        @media ${theme.media.large} {
            flex: unset;
        }

        @media ${theme.media.medium} {
            height: 480px;
        }

        @media ${theme.media.small} {
            height: 343px;
            width: auto;
        }
    `};
`;
