import styled, { css } from "styled-components";
import { Header3 } from "../../../ui/text/HeaderComponents";


export const DevelopingSectionContainer = styled.section`
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    padding: 128rem 40rem;
    display: flex;
    flex-direction: column;
    gap: 64rem;

    ${({ theme }) => css`
        @media ${theme.media.medium} { 
            gap: 32px;
            padding: 96px 16px;
        }
    `};
`;


export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40rem;

    ${({ theme }) => css`
        @media ${theme.media.medium} {
            flex-direction: column;
            gap: 16px;
        }
    `};
`;

export const Card = styled.div`
    flex: 1 0 0;
    align-self: stretch;
    
    display: flex;
    flex-direction: column;
    gap: 128rem;
    
    padding: 32rem;
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.neutral_500};

    ${({ theme }) => css`
        @media ${theme.media.medium} {
            gap: 64px;
            padding: 24px;
        }
    `};
`;


export const CardNumber = styled(Header3)`
    color: ${({ theme }) => theme.colors.white};
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.neutral_500};
`;

export const ContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16rem;
`;
