import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";


export const NavBarContainer = styled.nav`
    width: 100%;

    @media (max-width: 560px) {
        display: none;
    }
`;


export const LinksList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.black};
    
    @media (max-width: 560px) {
        flex-direction: column;
        padding: 16px 16px 32px;
        text-align: center;
        gap: 8px;
    }
`;

export const MenuLinkContainer = styled.li`
    padding: 8rem 16rem;

    ${({theme}) => css`
        @media ${theme.media.medium} {
            padding: 8px 16px;
        }
        @media (max-width: 560px) {
            padding: 6px 16px;
        }
    `}
`;

export const MenuLink = styled(NavLink)`
    color: ${({ theme }) => theme.colors.white};
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;

    &.active {
        text-decoration: underline;
        text-decoration-color: ${({ theme }) => theme.colors.white};
    }

    &:hover {
        text-decoration: underline;
        text-decoration-color: ${({ theme }) => theme.colors.primary_300};
    }

    ${({ theme }) => css`
        @media ${theme.media.medium} {
            font-size: 16px;
        }
    `}
`;


export const MobileMenuContainer = styled.div`
    display: none;

    @media (max-width: 560px) {
        display: block;
    }
`;

export const MobileMenuPopup = styled.div<{ $show: boolean }>`
    display: ${({ $show }) => $show ? "block" : "none" };
    position: absolute;
    left: 0;
    width: 100%;
    height: 100dvh;

    ${({theme}) => css`
        top: ${theme.sizes.header.height};
        
        @media ${theme.media.medium} {
            top: ${theme.sizes.header.mobileHeight};
        }
    `}
`;

export const Backdrop = styled.div`
    height: 100%;
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(3px);
`;

export const OpenMenuButton = styled.button.attrs(() => ({
    type: "button",
}))`
    background-color: transparent;
    border: none;
    padding: 0;
    height: 32px;

    svg {
        height: 32px;
        width: 32px;
    }
`;
