import { Trans, useTranslation } from "react-i18next";
import { ParagraphXL } from "../../../ui/text/ParagraphComponents";
import { SpanBase } from "../../../ui/text/SpanComponents";
import {
    Description,
    Header, Name,
    PrincipleBlock,
    PrinciplesSectionContainer
} from "./CulturePagePrinciplesSectionComponents";


const CulturePagePrinciplesSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "culture"});

    const principles = t("principles", { returnObjects: true });

    return (
        <PrinciplesSectionContainer>
            <Header>
                <Trans
                    t={t}
                    i18nKey="header"
                    components={{ span: <SpanBase $color="primary_300" /> }}
                />
            </Header>
            {principles.map(({ name, description }, index) => (
                <PrincipleBlock key={name}>
                    <ParagraphXL $weight={600}>0{index + 1}</ParagraphXL>
                    <Name $weight={700}>{name}</Name>
                    <Description $color="neutral_400">{description}</Description>
                </PrincipleBlock>
            ))}
        </PrinciplesSectionContainer>
    );
};

export default CulturePagePrinciplesSection;
