import { css, DefaultTheme } from "styled-components";

export interface TextProps {
    $weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
    $color?: keyof DefaultTheme["colors"];
    $marginBottom?: number;
    $textAlign?: "start" | "end" | "center" | "justify";
}
export const textStyles = css<TextProps>`
    color: ${({ $color, theme }) => $color? theme.colors[$color] : "inherit"};
    font-weight:  ${({ $weight }) => $weight || "inherit"};
    text-align: ${({ $textAlign }) => $textAlign || "start"};
    margin-bottom: ${({ $marginBottom }) => $marginBottom ? `${$marginBottom}rem` : "unset"};

    @media ${({ theme }) => theme.media.medium} {
        margin-bottom: ${({ $marginBottom }) => $marginBottom ? `${$marginBottom}px` : "unset"};
    }
`;
