import { Trans, useTranslation } from "react-i18next";
import { SpanBase } from "../../../ui/text/SpanComponents";
import { ParagraphM } from "../../../ui/text/ParagraphComponents";
import {
    OurLifeSectionContainer,
    ContentContainer,
    Header,
    DescriptionBlock,
    OurLifeImageContainer, TextWrapping,
} from "./CulturePageOurLifeSectionComponents";


const CulturePageOurLifeSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "culture.ourLife" });

    return (
        <OurLifeSectionContainer>
            <ContentContainer>
                <Header>
                    <Trans
                        t={t}
                        i18nKey="header"
                        components={{ br: <TextWrapping />, span: <SpanBase $color="primary_300" /> }}
                    />
                </Header>
                <DescriptionBlock>
                    <ParagraphM $color="neutral_500" $marginBottom={8}>
                        {t("description1")}
                    </ParagraphM>
                    <ParagraphM $color="neutral_500">
                        {t("description2")}
                    </ParagraphM>
                </DescriptionBlock>
            </ContentContainer>
            <OurLifeImageContainer />
        </OurLifeSectionContainer>
    );
};

export default CulturePageOurLifeSection;
