import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import RoutesMap, { MenuPages } from "../../constants/routes";
import { LinksList, MenuLink, MenuLinkContainer, NavBarContainer } from "./MenuComponents";

const NavBar = (props: HTMLAttributes<HTMLElement>) => {
    const { t } = useTranslation("translation", { keyPrefix: "menu" });

    return (
        <NavBarContainer {...props}>
            <LinksList>
                {MenuPages.map((pageName) => (
                    <MenuLinkContainer key={pageName}>
                        <MenuLink to={RoutesMap[pageName]}>
                            {t(pageName)}
                        </MenuLink>
                    </MenuLinkContainer>
                ))}
            </LinksList>
        </NavBarContainer>
    );
};

export default NavBar;
