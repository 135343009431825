import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import officeRoomImage from "../../../../resources/images/office-room.jpg";
import { ReactComponent as CompanyNameOldIcon } from "../../../../resources/icon/company-name-old.svg";
import { ReactComponent as CompanyNameIcon } from "../../../../resources/icon/logo.svg";


export const ImageSectionContainer = styled.section`
    padding: 256rem 40rem 128rem;
    display: flex;
    flex-direction: column;
    gap: 48rem;
    
    svg {
        width: 100%;
        height: auto;
    }

    ${({ theme }) => css`
        @media ${theme.media.large} { 
           padding: 128rem 40rem;
        }
        
        @media ${theme.media.medium} { 
            gap: 32px;
            padding: 64px 16px 96px;
        }
    `};
`;

export const DesktopCompanyNameIcon = styled(CompanyNameOldIcon)`
    display: block;

    ${({ theme }) => css`        
        @media ${theme.media.small} { 
            display: none;
        }
    `};
`;

export const MobileCompanyNameIcon = styled(CompanyNameIcon)`
    display: none;

    ${({ theme }) => css`        
        @media ${theme.media.small} { 
            display: block;
        }
    `};
`;

export const ImageWrapper = styled.div`
    width: 100%;
    height: 765rem;
    position: relative;
    overflow: hidden;
    border-radius: 16px;

    ${({ theme }) => css`
        @media ${theme.media.medium} { 
            height: 457px;
        }
    `};
`;

export const ImageContainer = styled(motion.div)`
    position: absolute;
    top: -110rem;
    z-index: 0;
    height: 875rem;
    width: 100%;
    //width: max(100%, calc(875rem * 1024 / 1536));
    //height: calc(100vw * 1536 / 1024);
    //width: 100vw;
    
    
    // 1024 x 1536
    background-image: url(${officeRoomImage}) ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    ${({ theme }) => css`
        @media ${theme.media.medium} {
            height: 543px;
            top: -44px;
        }
    `};
`;
