import { useTranslation } from "react-i18next";
import RoutesMap, { MenuPages } from "../../constants/routes";
import {
    Backdrop,
    LinksList, MenuLink, MenuLinkContainer,
    MobileMenuContainer, MobileMenuPopup, OpenMenuButton,
} from "./MenuComponents";

import { ReactComponent as MenuOpenIcon } from "../../resources/icon/menu-open.svg";
import { ReactComponent as MenuCloseIcon } from "../../resources/icon/menu-close.svg";

type MobileMenuProps = {
    open: boolean;
    toggleOpen: () => void;
}

export const MobileMenu = ({ open, toggleOpen }: MobileMenuProps) => {
    const { t } = useTranslation("translation", { keyPrefix: "menu" });

    return (
        <MobileMenuContainer>
            <OpenMenuButton onClick={toggleOpen}>
                {open ? (<MenuCloseIcon />) : (<MenuOpenIcon />)}
            </OpenMenuButton>
            <MobileMenuPopup $show={open}>
                <LinksList>
                    {MenuPages.map((pageName) => (
                        <MenuLinkContainer key={pageName}>
                            <MenuLink to={RoutesMap[pageName]} onClick={toggleOpen}>
                                {t(pageName)}
                            </MenuLink>
                        </MenuLinkContainer>
                    ))}
                </LinksList>
                <Backdrop onClick={toggleOpen} />
            </MobileMenuPopup>
        </MobileMenuContainer>
    );
};

export default MobileMenu;
