import styled, { css } from "styled-components";
import { ButtonSizeType, ButtonVariantType } from "./buttonTypes";

const filledButtonHoverStyle = css`
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary_500};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const tertiaryButtonHoverStyle = css`
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.primary_500}`};
    color: ${({ theme }) => theme.colors.white};
  }
  
  &:disabled {
    &:hover {
      border: ${({ theme }) => `1px solid ${theme.colors.neutral_300}`};
    }
  }
`;


export const BaseButtonComponent = styled.button<{
  $variant: ButtonVariantType,
  $size: ButtonSizeType,
  $isLoading?: boolean,
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 200px;
  border: none;

  text-transform: uppercase;
  
  ${({ theme, $size }) => {
    switch ($size) {
      case "L":
        return css`
          padding: 16rem 32rem;
          font-size: 21rem;
          line-height: 21rem;
          gap: 8rem;
          
          @media ${theme.media.medium} {
            padding: 16px 32px;
            font-size: 21px;
            line-height: 21px;
            gap: 8px;
          }`;
      case "S":
        return css`
          padding: 7rem 12rem;
          font-size: 16rem;
          line-height: normal;
          gap: 4rem;
          
          svg {
            height: 16rem;
            width: 16rem;
          }

          @media ${theme.media.medium} {
            padding: 7px 12px;
            font-size: 16px;
            gap: 4px;

            svg {
              height: 16px;
              width: 16px;
            }
          }
        `;
      default:
        return "";
    }
  }};

  ${({ theme, $variant, $isLoading }) => {
    if ($isLoading) {
      return css`
        background-color: ${theme.colors.primary_800};
        border: 1px solid ${theme.colors.primary_800};
        color: ${theme.colors.white};
        cursor: not-allowed;
        
        color: ${theme.colors.white};
        background-color: ${theme.colors.primary_800};
      `;
    } else {
      switch ($variant) {
        case "primary":
          return css`
            background-color: ${theme.colors.primary_300};
            color: ${theme.colors.white};
            
            ${filledButtonHoverStyle};
          `;
        case "lightSecondary":
          return css`
            background-color: ${theme.colors.white};
            color:  ${theme.colors.neutral_800};
            
            ${filledButtonHoverStyle};
          `;
        case "darkSecondary":
          return css`
            background-color: ${theme.colors.neutral_800};
            color: ${theme.colors.white};
            
            ${filledButtonHoverStyle};
          `;
        case "lightTertiary":
          return css`
            background-color: transparent;
            border: 1px solid ${theme.colors.neutral_800};
            color: ${theme.colors.neutral_800};
            
            ${tertiaryButtonHoverStyle}
          `;
        case "darkTertiary":
          return css`
            background-color: transparent;
            border: 1px solid ${theme.colors.white};
            color: ${theme.colors.white};
            
            ${tertiaryButtonHoverStyle}
          `;
        default:
          return "";
      }
    }
  }}
  
  &:active {
    background-color:${({theme}) => theme.colors.primary_800};
    color:  ${({theme}) => theme.colors.white};
  }
  
  &:disabled {
    color: ${({ theme }) => theme.colors.neutral_500};
    background-color: ${({ theme }) => theme.colors.neutral_300};
    cursor: not-allowed;

    &:hover {
      color: ${({ theme }) => theme.colors.neutral_500};
      background-color: ${({ theme }) => theme.colors.neutral_300};
    }

    svg {
      fill: ${({ theme }) => theme.colors.neutral_500};
    }
  }
`;
