import { useRef } from "react";
import { useScroll, useTransform } from "framer-motion";
import {
    ImageSectionContainer,
    DesktopCompanyNameIcon,
    MobileCompanyNameIcon,
    ImageWrapper,
    ImageContainer,
} from "./CulturePageImageSectionComponents";


const CulturePageImageSection = () => {
    const parallaxContainerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: parallaxContainerRef,
        offset: ["start end", "end start"],
    });

    const y = useTransform(scrollYProgress, [0, 1], ["-10%", "10%"]);

    return (
        <ImageSectionContainer ref={parallaxContainerRef}>
            <DesktopCompanyNameIcon />
            <MobileCompanyNameIcon />
            <ImageWrapper>
                <ImageContainer style={{ y }} />
            </ImageWrapper>
        </ImageSectionContainer>
    );
};

export default CulturePageImageSection;
