import styled, { css } from "styled-components";
import { Header2, Header3 } from "../../../ui/text/HeaderComponents";
import { ParagraphM } from "../../../ui/text/ParagraphComponents";

export const PrinciplesSectionContainer = styled.section`
    padding: 128rem 40rem 256rem;

    ${({ theme }) => css`
        @media ${theme.media.medium} {
            padding: 96px 16px 192px;
        }
    `};
`;

export const Header = styled(Header2)`
    margin-bottom: 96rem;

    ${({ theme }) => css`
        @media ${theme.media.medium} {
            margin-bottom: 48px;
        }
    `};
`;

export const PrincipleBlock = styled.div`
    display: flex;
    flex-direction: row;
    gap: 80rem;
    padding: 32rem 0 96rem;
    border-bottom: 1px solid ${({ theme}) => theme.colors.neutral_400};
    
    &:last-child {
        border-bottom: none;
    }

    ${({ theme }) => css`
        @media ${theme.media.large} {
            gap: 60rem;
        }
        
        @media ${theme.media.medium} {
            flex-direction: column;
            gap: 24px;
            padding: 24px 0 48px;
        }
    `};
`;

export const Name = styled(Header3)`
    flex-grow: 1;
`;

export const Description = styled(ParagraphM)`
    width: 586rem;

    ${({ theme }) => css`
        @media ${theme.media.large} {
            width: 353rem;
        }
        @media ${theme.media.medium} {
            width: 100%;
        }
    `};
`;
