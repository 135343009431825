import styled from "styled-components";
import { motion } from "framer-motion";
import { ParagraphL } from "../../../ui/text/ParagraphComponents";
import { Header2 } from "../../../ui/text/HeaderComponents";
import invitationBgImage from "../../../../resources/images/invitation-background.jpg";

export const InvitationBlockContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding: 128rem 40rem;
    height: 100vh;
    
    position: relative;
    overflow: hidden;
`;

export const HeaderBlock = styled(Header2)`
    padding: 0 120rem;
    margin-bottom: 48rem;
    
    text-align: center;
    font-weight: 700;
    z-index: 20;

    @media ${({ theme }) => theme.media.large} { 
        padding: 0;
    }

    @media ${({ theme }) => theme.media.medium} {
        margin-bottom: 32px;
    }
`;

export const DescriptionBlock = styled(ParagraphL)`
    text-align: center;
    line-height: 140%;
    z-index: 20;
`;

export const BackgroundContainer = styled(motion.div)`
    position: absolute;
    top: -20%;
    z-index: 0;
    height: 200vh;
    width: max(100%, calc(200vh * 1024 / 1536));
    //height: calc(100vw * 1536 / 1024);
    //width: 100vw;
    
    
    // 1024 x 1536
    background-image: url(${invitationBgImage}) ;
    background-repeat: no-repeat;
    background-size: cover;
`;
