import CulturePageImageSection from "./imageSection/CulturePageImageSection";
import CulturePagePrinciplesSection from "./principlesSection/CulturePagePrinciplesSection";
import CulturePageOurLifeSection from "./ourLifeSection/CulturePageOurLifeSection";
import CulturePageDevelopingSection from "./developingSection/CulturePageDevelopingSection";


const CulturePage = () => {


    return (
        <>
            <CulturePageImageSection />
            <CulturePagePrinciplesSection />
            <CulturePageOurLifeSection />
            <CulturePageDevelopingSection />
        </>
    );
}

export default CulturePage;
