import styled from "styled-components";
import NavBar from "../menu/NavBar";
import { ReactComponent as LogoIcon } from "../../resources/icon/logo.svg";

export const FooterContainer = styled.footer`
    background-color: ${({ theme }) => theme.colors.black};
    padding: 9rem 40rem 64rem 40rem;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24rem;
    
    button {
        width: fit-content;
    }

    @media ${({ theme }) => theme.media.medium} {
        padding: 9px 16px 64px 16px;
        gap: 48px;

        button {
            width: 100%;
        }
    }
`;

export const FooterNavBar = styled(NavBar)`
    height: fit-content;
    width: fit-content;
    
    li {
        padding: 0;
    }

    ul {
        padding: 0;
        justify-content: center;
        gap: 32rem;

        @media (max-width: 560px) {
            flex-direction: row;
        }
    }

    @media ${({ theme }) => theme.media.medium} {
        order: 1;
    }

    @media (max-width: 560px) {
        display: block;
    }
`;

export const MainInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    @media ${({ theme }) => theme.media.medium} {
        flex-direction: column-reverse;
        align-items: center;
        gap: 48px;
    }
`;

export const LogoIconComponent = styled(LogoIcon)`
    height: 60rem;
    width: auto;

    @media ${({ theme }) => theme.media.medium} {
        height: 60px;
    }
`;

export const ContactsBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8rem;
    
    height: 60rem;
    
    color: ${({ theme }) => theme.colors.white};

    @media ${({ theme }) => theme.media.medium} {
        height: unset;
        flex-direction: row;
        gap: 24px;
    }
`;
