import { Trans, useTranslation } from "react-i18next";
import { Header2 } from "../../../ui/text/HeaderComponents";
import { SpanBase } from "../../../ui/text/SpanComponents";
import { ParagraphM, ParagraphXL } from "../../../ui/text/ParagraphComponents";
import {
    DevelopingSectionContainer,
    CardsContainer,
    ContentBlock,
    Card,
    CardNumber,
} from "./CulturePageDevelopingSectionComponents";


const CulturePageDevelopingSection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "culture.developing"});

    const cards = t("cards", { returnObjects: true });

    return (
        <DevelopingSectionContainer>
            <Header2 $textAlign="center">
                <Trans
                    t={t}
                    i18nKey="header"
                    components={{ span: <SpanBase $color="primary_300" /> }}
                />
            </Header2>
            <CardsContainer>
                {cards.map(({ name, description }, index) => (
                    <Card key={name}>
                        <CardNumber $weight={900}>0{index + 1}</CardNumber>
                        <ContentBlock>
                            <ParagraphXL $weight={600}>{name}</ParagraphXL>
                            <ParagraphM $color="neutral_500">{description}</ParagraphM>
                        </ContentBlock>
                    </Card>
                ))}
            </CardsContainer>
        </DevelopingSectionContainer>
    );
};

export default CulturePageDevelopingSection;
