import { useEffect } from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
import { useLenis } from "@studio-freight/react-lenis";
import { ThemeProvider } from "styled-components";
import RoutesMap from "./constants/routes";
import Layout from "./components/layout/Layout";
import MainPage from "./components/pages/mainPage/MainPage";
import CulturePage from "./components/pages/culturePage/CulturePage";
import GlobalStyles from "./styles/global";
import { baseTheme } from "./styles/theme";

const App = () => {
    const { pathname } = useLocation();
    const lenisInstance = useLenis();

    useEffect(() => {
        if (lenisInstance) {
            lenisInstance.scrollTo("top");
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [pathname]);

    return (
        <ThemeProvider theme={baseTheme}>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route path={RoutesMap.main} element={<MainPage />} />
                    <Route path={RoutesMap.culture} element={<CulturePage />} />
                    <Route path="*" element={<Navigate to={RoutesMap.main} replace /> } />
                </Route>
            </Routes>
            <GlobalStyles />
        </ThemeProvider>
    );
};

export default App;
